.container {
  display: grid;
  grid-template-rows: 1fr repeat(3, 1fr) 1fr;
  height: 100vh;
  padding: 0 calc(var(--basis-height) / 3) 0 calc(var(--basis-height) / 3);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content.content__condensed {
  justify-content: flex-start;
}

.content .content_item_bottom {
  margin-top: auto;
}

.logo {
  display: block;
  background-image: url(./logo.svg);
  background-repeat: no-repeat;
  background-size: contain;
  min-height: var(--font-size-md);
  color: transparent;
}

.text_font-size-lg {
  font-size: var(--font-size-lg);
}

.text_font-size-md {
  font-size: var(--font-size-md);
}

.text__uppercase {
  text-transform: uppercase;
}

/*  X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) and (orientation: portrait) {
  .logo {
    min-height: var(--font-size-md);
  }

  .text_font-size-lg {
    font-size: var(--font-size-sm);
  }
  
  .text_font-size-md {
    font-size: var(--font-size-xs);
  }
}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    .text_font-size-lg {
      font-size: var(--font-size-md);
    }
    
    .text_font-size-md {
      font-size: var(--font-size-sm);
    }
}
